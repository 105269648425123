import { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import ProjectContext, { ProjectContextT } from 'contexts/ProjectContext'
import {
  ProjectT,
  BaseScopeT,
  SowItemDictT,
  DictT,
  ProjectDictsT,
  NoteT,
  BaseNotesT,
  DBProjectScopeT,
  SectionT,
  TotalRowT,
  TableRowT,
  ReportTemplateInfoT
} from 'model/types'
import { useSelector } from 'model/hooks'
import _ from 'lodash'
import {
  dbCreateProject,
  fetchProjectDicts,
  fetchProjectScopeOnce,
  dbUpdateScope,
  saveDicts,
  dbUpdateProject,
  dbCreateReportTemplate,
  dbUpdateReportTemplate,
  dbFetchTemplate
} from 'controllers/projects'
import Loading from 'pages/Loading'
import { EXCLUSIONS_SECTION_ID, TRADE_SECTION_ID } from 'constants/reportSections'
import { generateId } from 'controllers/db'

const ProjectDataProvider = () => {
  const { projectId } = useParams()
  if (_.isNil(projectId)) {
    console.error('projectId missing in url')
    return null
  }
  // const projectScopeListenerRef = useRef<Unsubscribe | null>(null)
  const [dicts, setDicts] = useState<ProjectDictsT | null>(null)
  const [hasChanges, setHasChanges] = useState(false)
  const user = useSelector(state => state.user)
  const detailsDictAirtable = useSelector(state => state.projectDetails)
  const presetsAirtable = useSelector(state => state.projectPresets)
  const scopeSectionsAirtable = useSelector(state => state.scopeSectionsDict)
  const tradesDictAirtable = useSelector(state => state.tradesDict)
  const sowItemsAirtable = useSelector(state => state.sowItemsDict)
  const notesDictAirtable = useSelector(state => state.notesDict)
  const dbProject = useSelector(state => state.projects[projectId])
  const isNew = _.isEmpty(dbProject)
  const [newProject, setNewProject] = useState<ProjectT>({ id: projectId })
  const [excludedScopeItems, setExcludedScopeItems] = useState<string[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [_sectionsOrder, _setSectionsOrder] = useState<string[]>([])
  const [_sections, _setSections] = useState<DictT<SectionT>>({})
  const [scopeHidden, _setScopeHidden] = useState(false)
  const [totalsItems, _setTotalsItems] = useState<DictT<TotalRowT>>({})
  const [costPlus, _setCostPlus] = useState<number | undefined>()
  const [reportChanged, setReportChanged] = useState(false)
  const [currentTemplateId, setCurrentTemplateId] = useState<string | undefined>()

  console.log('context: project isNew', isNew)

  const excludedScopeItemsDict: DictT<string> = useMemo(() => _.keyBy(excludedScopeItems), [excludedScopeItems])
  console.log('excludedScopeItemsDict', excludedScopeItemsDict)

  useEffect(() => {
    if (dbProject) {
      setNewProject(dbProject)
    }
  }, [dbProject])

  const detailsDict = useMemo(() => {
    return dicts?.detailsDict || detailsDictAirtable
  }, [detailsDictAirtable, dicts])

  const presets = useMemo(() => {
    return dicts?.presets || presetsAirtable
  }, [presetsAirtable, dicts])

  const scopeSections = useMemo(() => {
    return dicts?.scopeSections || scopeSectionsAirtable
  }, [scopeSectionsAirtable, dicts])

  const tradesDict = useMemo(() => {
    return dicts?.tradesDict || tradesDictAirtable
  }, [tradesDictAirtable, dicts])

  const sowItems = useMemo(() => {
    return dicts?.scopeItemsDict || sowItemsAirtable
  }, [sowItemsAirtable, dicts])

  const notesDict = useMemo(() => {
    return dicts?.notesDict || notesDictAirtable
  }, [notesDictAirtable, dicts])

  useEffect(() => {
    if (dbProject) {
      const fetchData = async () => {
        setIsDataLoading(true)
        console.log('%cproject is not new: fetch Dicts', 'color: orange;')
        const dbDicts = await fetchProjectDicts(projectId)
        console.log('%cproject is not new: dicts received', 'color: orange;')
        setDicts(dbDicts)
        const dbProjectScope = await fetchProjectScopeOnce(projectId)
        console.log('%cdbProjectScope', 'color: orange', dbProjectScope)
        if (dbProjectScope) {
          setExcludedScopeItems(dbProjectScope.excludedScopeItems || [])
          _setSectionsOrder(dbProjectScope.sectionsOrder || [])
          _setSections(dbProjectScope.sections || {})
          _setScopeHidden(_.get(dbProjectScope, 'scopeHidden', false))
          _setTotalsItems(dbProjectScope.totalsItems || {})
          _setCostPlus(dbProjectScope.costPlus && dbProjectScope.costPlus > 0 ? dbProjectScope.costPlus : undefined)
        }
        setIsDataLoading(false)
      }
      fetchData()
    }
  }, [])

  const baseScope = useMemo(() => {
    const res: BaseScopeT = {}
    const pDetails = _.get(newProject, 'details', [])
    _.forEach(sowItems, (sowItem: SowItemDictT) => {
      const applicable = sowItem.applicable
      let isNeeded = true
      if (!_.isEmpty(applicable)) {
        const ints = _.difference(applicable, pDetails)
        isNeeded = _.isEmpty(ints)
      }
      if (isNeeded && sowItem.tradeId && sowItem.sectionId) {
        _.set(res, [sowItem.tradeId, sowItem.sectionId, sowItem.id], sowItem)
      }
    })
    return res
  }, [newProject, sowItems])

  const projectScope = useMemo(() => {
    const res: BaseScopeT = {}
    _.forEach(baseScope, (sections, tradeId) => {
      _.forEach(sections, (sowItems, sectionId) => {
        _.forEach(sowItems, (item, itemId) => {
          const isExcluded = _.has(excludedScopeItemsDict, itemId)
          if (!isExcluded) {
            _.set(res, [tradeId, sectionId, itemId], item)
          }
        })
      })
    })
    return res
  }, [newProject, baseScope])

  const baseNotes = useMemo(() => {
    const res: BaseNotesT = {}
    const pDetails = _.get(newProject, 'details', [])
    _.forEach(notesDict, (note: NoteT) => {
      const applicable = note.applicable
      let isNeeded = true
      if (!_.isEmpty(applicable)) {
        const ints = _.difference(applicable, pDetails)
        isNeeded = _.isEmpty(ints)
      }
      if (isNeeded && note.sectionId) {
        _.set(res, [note.sectionId, note.id], note)
      }
    })
    return res
  }, [newProject, notesDict])

  const projectNotes = useMemo(() => {
    const res: BaseNotesT = {}
    _.forEach(baseNotes, (notes, sectionId) => {
      _.forEach(notes, (note, noteId) => {
        const isExcluded = _.has(excludedScopeItemsDict, noteId)
        if (!isExcluded) {
          _.set(res, [sectionId, noteId], note)
        }
      })
    })
    return res
  }, [newProject, baseNotes])

  const updateProject = (params: Partial<ProjectT>) => {
    console.log('updateProject', params)
    setHasChanges(true)
    setNewProject(newProject => ({ ...newProject, ...params }))
  }

  const saveProject = () => {
    // console.log('saveProject, excludedScopeItems', excludedScopeItems)
    const p: ProjectT = {
      ...newProject,
      accountId: user.currentAccountId,
      createdBy: user.id,
      scopeBuilder: true
    }
    const dbProjectScope = {
      excludedScopeItems
    }
    if (isNew) {
      console.log('%cproject is new, call dbCreateProject')
      _.set(p, 'createdAt', _.now())
      const dicts: ProjectDictsT = {
        tradesDict,
        scopeSections,
        presets,
        detailsDict,
        scopeItemsDict: sowItems,
        notesDict
      }
      dbCreateProject(p, dbProjectScope, dicts)
    } else if (p.id) {
      console.log('%cproject is NOT new, call update')
      _.set(p, 'updatedAt', _.now())
      dbUpdateScope(p.id, dbProjectScope)
      dbUpdateProject(p)
    }
    setHasChanges(false)

    // console.log('project to save', p)
  }

  const toggleSowItem = useCallback(
    (itemId: string) => {
      console.log('toggleSowItem', itemId)
      setHasChanges(true)
      console.log('current excludedScopeItemsDict', excludedScopeItemsDict)
      if (_.has(excludedScopeItemsDict, itemId)) {
        setExcludedScopeItems(excludedScopeItems => _.filter(excludedScopeItems, id => id !== itemId))
      } else {
        setExcludedScopeItems(excludedScopeItems => [...excludedScopeItems, itemId])
      }
    },
    [excludedScopeItemsDict]
  )

  const updateExcludedScopeItems = (itemsIds: string[]) => {
    setExcludedScopeItems(itemsIds)
  }

  const reloadAirtable = async () => {
    console.log('reload airtable')
    const projectId = newProject?.id
    if (!projectId) {
      console.error('no project id')
      return null
    }
    const dicts: ProjectDictsT = {
      tradesDict: tradesDictAirtable,
      scopeSections: scopeSectionsAirtable,
      presets: presetsAirtable,
      detailsDict: detailsDictAirtable,
      scopeItemsDict: sowItemsAirtable,
      notesDict: notesDictAirtable
    }
    await saveDicts(projectId, dicts)
    setDicts(dicts)
  }

  const updateScope = (pScope: Partial<DBProjectScopeT>) => {
    if (newProject.id) {
      dbUpdateScope(newProject.id, pScope)
      setReportChanged(true)
    } else {
      console.error('update scope: project has no ID')
    }
  }

  const baseTradesRows = useMemo(() => {
    const tradesRows: DictT<TableRowT> = {}
    const tradesIds = _.sortBy(_.keys(projectScope), tradeId => tradesDict[tradeId])
    _.forEach(tradesIds, (tradeId, i) => {
      const rowData: TableRowT = {
        id: tradeId,
        name: _.get(tradesDict, tradeId),
        createdAt: i
      }
      tradesRows[tradeId] = rowData
    })
    console.log('%cuse memo baseTradesRows', 'color: green;', tradesRows)
    return tradesRows
  }, [projectScope])

  const sections = useMemo(() => {
    if (_.isEmpty(_sections)) {
      console.log('Report editor content init')
      return {
        [TRADE_SECTION_ID]: {
          id: TRADE_SECTION_ID,
          name: 'TRADES',
          rows: { ...baseTradesRows }
        },
        [EXCLUSIONS_SECTION_ID]: {
          id: EXCLUSIONS_SECTION_ID,
          name: 'Exclusions',
          rows: {}
        }
      }
    } else {
      return _sections
    }
  }, [_sections, _sectionsOrder])

  const sectionsOrder: string[] = useMemo(() => {
    if (_.isEmpty(_sectionsOrder)) {
      return [TRADE_SECTION_ID]
    } else {
      return _sectionsOrder
    }
  }, [_sectionsOrder])

  const setSectionsOrder = (sectionsOrder: string[]) => {
    _setSectionsOrder(sectionsOrder)
    updateScope({ sectionsOrder })
  }

  const setSections = (sections: DictT<SectionT>) => {
    _setSections(sections)
    updateScope({ sections })
  }

  const setScopeHidden = (scopeHidden: boolean) => {
    _setScopeHidden(scopeHidden)
    updateScope({ scopeHidden })
  }

  const setTotalsItems = (totalsItems: DictT<TotalRowT>) => {
    _setTotalsItems(totalsItems)
    updateScope({ totalsItems })
  }

  const setCostPlus = (costPlus: number | undefined) => {
    _setCostPlus(costPlus)
    updateScope({ costPlus })
  }

  const createTemplate = () => {
    const filteredSections = {}
    _.forEach(sections, (s, sId) => {
      const newRows = _.reduce(
        s.rows,
        (res, r, rId) => {
          if (!_.has(tradesDict, rId)) {
            _.set(res, rId, r)
          }
          return res
        },
        {}
      )
      _.set(filteredSections, sId, { ...s, rows: newRows })
    })
    const template: DBProjectScopeT = {
      accountId: user.currentAccountId,
      sectionsOrder,
      sections: filteredSections,
      scopeHidden,
      totalsItems
    }
    if (!_.isNil(costPlus)) template.costPlus = costPlus
    return template
  }

  const createReportTemplate = (name: string) => {
    const t = createTemplate()
    const templateId = generateId()
    const templateInfo: ReportTemplateInfoT = {
      id: templateId,
      name,
      createdAt: _.now()
    }
    console.log('createReportTemplate, name', name, t)
    dbCreateReportTemplate(templateInfo, t)
    setReportChanged(false)
    setCurrentTemplateId(templateId)
  }

  const updateReportTemplate = (templateId: string) => {
    console.log('createReportTemplate, name', templateId)
    const t = createTemplate()
    dbUpdateReportTemplate(templateId, t)
    setReportChanged(false)
    setCurrentTemplateId(templateId)
  }

  const loadTemplate = async (templateId: string | undefined) => {
    if (templateId) {
      const t = await dbFetchTemplate(templateId)
      const newSections = _.reduce(
        t.sections,
        (res, s, sId) => {
          if (sId === TRADE_SECTION_ID) {
            const curTradeSection = _.get(sections, TRADE_SECTION_ID)
            _.forEach(curTradeSection.rows, (r, rId) => {
              if (_.has(tradesDict, rId)) {
                _.set(s, ['rows', rId], r)
              }
            })
          }
          _.set(res, sId, s)
          return res
        },
        {}
      )
      updateScope({
        sectionsOrder: t.sectionsOrder,
        sections: newSections,
        costPlus: t.costPlus,
        scopeHidden: t.scopeHidden,
        totalsItems: t.totalsItems
      })
      _setScopeHidden(t.scopeHidden)
      _setSectionsOrder(t.sectionsOrder)
      _setCostPlus(t.costPlus)
      _setSections(newSections)
      _setTotalsItems(t.totalsItems)
    } else {
      updateScope({
        sectionsOrder: [],
        sections: {},
        costPlus: 0,
        scopeHidden: false,
        totalsItems: {}
      })
      _setScopeHidden(false)
      _setSectionsOrder([])
      _setCostPlus(undefined)
      _setSections({})
      _setTotalsItems({})
    }
    setCurrentTemplateId(templateId)
    setReportChanged(false)
    return null
  }

  const contextValue: ProjectContextT = {
    project: newProject,
    updateProject,
    isNew,
    baseScope,
    tradesDict,
    scopeSections,
    projectScope,
    hasChanges,
    saveProject,
    excludedScopeItemsDict,
    toggleSowItem,
    presets,
    detailsDict,
    updateExcludedScopeItems,
    isDataLoading,
    reloadAirtable,
    baseNotes,
    projectNotes,
    sectionsOrder,
    setSectionsOrder,
    sections,
    setSections,
    scopeHidden,
    setScopeHidden,
    totalsItems,
    setTotalsItems,
    costPlus,
    setCostPlus,
    reportChanged,
    createReportTemplate,
    updateReportTemplate,
    currentTemplateId,
    loadTemplate,
    baseTradesRows
  }

  return (
    <ProjectContext.Provider value={contextValue}>{isDataLoading ? <Loading /> : <Outlet />}</ProjectContext.Provider>
  )
}

export default ProjectDataProvider
