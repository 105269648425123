import _ from 'lodash'
import {
  onSnapshot,
  collection,
  query,
  where,
  deleteField,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  writeBatch
} from 'firebase/firestore'
import { db } from 'controllers/db'

import { receiveProjects } from 'model/actions/projectsAC'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { ProjectT, ProjectDictsT, DBProjectScopeT, ReportTemplateInfoT } from 'model/types'

export const fetchProjects = (accountId: string) => {
  try {
    console.log('fetch projects, accountId:', accountId)
    const q = query(collection(db, 'projects'), where('accountId', '==', accountId), where('scopeBuilder', '==', true))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        console.log('projects received, amount', _.size(res))
        store.dispatch(receiveProjects(res))
      },
      err => {
        console.log(`fetchProjects error: ${err}`)
        // Sentry.captureException(err)
      }
    )
    addListener('projects', unsubscribe)
  } catch (e) {
    console.log('fetchProjects error', e)
    // Sentry.captureException(e)
  }
}

export const dbUpdateProject = async (params: Partial<ProjectT>) => {
  const p: ProjectT = {
    ..._.omitBy(params, _.isNil),
    deleted: 0
  }
  if (p.id) {
    if (_.isNil(_.get(params, 'label'))) {
      _.set(p, 'label', deleteField())
    }
    console.log('save project', p)
    const state = store.getState()
    const ref = doc(db, 'projects', p.id)
    if (_.has(state, ['projects', p.id])) {
      await updateDoc(ref, { ...p })
    } else {
      await setDoc(ref, p, { merge: true })
    }
    console.log('project saved', p.id)
    return p.id
  }
}

export const fetchProjectDicts = async (projectId: string) => {
  const scopeDictsRef = doc(db, 'scopeDicts', projectId)
  const dictSN = await getDoc(scopeDictsRef)
  return dictSN.data() as ProjectDictsT
}

export const fetchProjectScope = async (projectId: string, callback: (projectScope: DBProjectScopeT) => void) => {
  const projectScopeRef = doc(db, 'projectScopes', projectId)
  const unsubscribe = onSnapshot(projectScopeRef, pscopeSN => {
    const projectScope = pscopeSN.data() as DBProjectScopeT
    callback(projectScope)
  })
  return unsubscribe
}

export const fetchProjectScopeOnce = async (projectId: string) => {
  const projectScopeRef = doc(db, 'projectScopes', projectId)
  const projectScopeSN = await getDoc(projectScopeRef)
  return projectScopeSN.data() as DBProjectScopeT
}

export const dbUpdateScope = async (projectId: string, projectScope: Partial<DBProjectScopeT>) => {
  console.log('dbCreateProject project', projectId)
  console.log('dbCreateProject excludedScopeItems', projectScope)
  try {
    console.log('save project scope')
    const projectScopeRef = doc(db, 'projectScopes', projectId)
    await setDoc(projectScopeRef, { ...projectScope }, { merge: true })
  } catch (e) {
    console.error('error', e)
  }
}

export const saveDicts = async (projectId: string, dicts: ProjectDictsT) => {
  console.log('save scopes dict')
  const scopeDictsRef = doc(db, 'scopeDicts', projectId)
  await setDoc(scopeDictsRef, dicts)
}

export const dbCreateProject = async (
  project: ProjectT,
  projectScope: Partial<DBProjectScopeT>,
  dicts: ProjectDictsT
) => {
  if (!project.id) return null
  console.log('dbCreateProject project', project)
  console.log('dbCreateProject excludedScopeItems', projectScope)
  console.log('dbCreateProject dicts', dicts)
  try {
    console.log('save project')
    await dbUpdateProject(project)
    await saveDicts(project.id, dicts)
    console.log('save project scope')
    const projectScopeRef = doc(db, 'projectScopes', project.id)
    await setDoc(projectScopeRef, projectScope)

    // const batch = writeBatch(db)
    // batch.set(doc(db, 'scopeDicts', project.id), dict)
    // batch.set(doc(db, 'projectScopes', project.id), projectScope)
    // batch.set(doc(db, 'projects', project.id), project)
    // await batch.commit()
  } catch (e) {
    console.error('error', e)
  }
}

export const dbCreateReportTemplate = async (templateInfo: ReportTemplateInfoT, template: DBProjectScopeT) => {
  if (template.accountId) {
    const templateRef = doc(db, 'reportTemplates', templateInfo.id)
    const batch = writeBatch(db)
    batch.set(templateRef, template)
    const accRef = doc(db, 'accounts', template.accountId)
    const upd = {
      [`reportTemplates.${templateInfo.id}`]: templateInfo
    }
    batch.update(accRef, upd)
    await batch.commit()
  } else {
    console.error('dbCreateReportTemplate, no accountId')
  }
}

export const dbUpdateReportTemplate = async (templateId: string, template: DBProjectScopeT) => {
  const templateRef = doc(db, 'reportTemplates', templateId)
  await setDoc(templateRef, template)
}

export const dbFetchTemplate = async (templateId: string) => {
  const templateRef = doc(db, 'reportTemplates', templateId)
  const sn = await getDoc(templateRef)
  return sn.data() as DBProjectScopeT
}
